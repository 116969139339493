.btn:not(.btn-group > .btn) {
   border-radius: 8px !important;
}
.btn.primary {
   background: var(--color1) !important;

}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
   color: var(--color5);
}
.btn-primary {
   --bs-btn-color: var(--color5);
   --bs-btn-bg: var(--color1);
   --bs-btn-border-color: var(--color1);
   --bs-btn-hover-color: var(--color5);
   --bs-btn-hover-bg: var(--color3);
   --bs-btn-hover-border-color: var(--color3);
   --bs-btn-focus-shadow-rgb: 49, 132, 253;
   --bs-btn-active-color: var(--color8);
   --bs-btn-active-bg: var(--color1);
   --bs-btn-active-border-color: var(--color3);
   --bs-btn-active-shadow: inset 0 3px 5px rgba(var(--color8),var(--alpha1));
   --bs-btn-disabled-color: var(--color8);
   --bs-btn-disabled-bg: var(--color1);
   --bs-btn-disabled-border-color: var(--color1);
}


.btn-group > .btn {
   border-style: solid;
   border-width: 1px;
   background: #fff;
   color: var(--color1);
}