.main > .authPage {
   width: 100%;
}
.authPage .content {
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
}
.authPage .form-control {
   background: rgba(255, 255, 255, 0.4);
   -webkit-transition: 0.3s;
   -o-transition: 0.3s;
   transition: 0.3s;
   backdrop-filter: blur(7px);
}

.rightRideimg {
   height: 100%;
}

.login-wrap.card {
   box-shadow: 0px 0px 50px 20px rgba(0,0,0,0.3);
}
