@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-size-adjust: 100%;
}

body {
  font-size: 14px;
}

.main {
  display: flex;
  font-family: "Public Sans", sans-serif;
}
.disabledDiv {
  opacity: 0.5;
  pointer-events: none;
}
.tooltip {
  position: absolute;
  z-index: 100000;
}
.ck-content {
  min-height: 100px;
}
.ck-balloon-panel {
  display: none !important;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.apexcharts-tooltip.apexcharts-theme-light.apexcharts-active {
  border-radius: 10px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}
.apexcharts-tooltip .apexcharts-tooltip-title {
  display: none;
}
/* .customMultiUpload {
   border: 3px dashed #dedede;
   padding: 16px;
   border-radius: 8px;
   text-align: center;
   position: relative;
 }
  */
.customMultiUpload .btn.btn-primary {
  height: 90px;
  width: 90px;
  border: 2px dashed #b6b6b6;
  background: transparent;
  color: #797979;
  font-weight: bold;
  background: #ddd;
  white-space: break-spaces;
}
.customMultiUpload {
  border: 3px dashed #dedede;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  display: flex;
  /* flex-direction: row-reverse; */
  align-items: center;
  justify-content: center;
}

.customMultiUpload input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.customMultiUpload h5 {
  font-size: 20px;
  color: var(--color3);
  display: inline-block;
}

.customMultiUpload small {
  color: rgba(199, 157, 52, 0.8);
}
.customMultiUpload .content {
  padding: 0;
}
.filePreview small {
  position: absolute;
  z-index: 0;
  bottom: 5px;
  background: #fff;
  border-radius: 10px;
  padding: 0px 10px;
  width: calc(100% - 8px);
  font-size: 11px;
  left: 4px;
  font-weight: 800;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.3);
}

.previewHolder {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1000;
  justify-content: center;
}

.filePreview {
  height: 90px;
  width: 80px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 10px 10px;
}

.filePreview img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.previewHolder .actionbtn {
  position: absolute;
  right: 5px;
  top: 5px;
}

.filePreview button {
  width: 25px;
  height: 25px;
  border-radius: 20px;
  border-width: 0px;
  background: #c79d34;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  flex-grow: 1;
  margin-top: 0px;
  width: 100%;
}

.container {
  max-width: 100%;
  padding: 0 24px;
}

.rangeSlider__ {
  background: var(--color2);
}

.rangeSlider__ > div {
  background: var(--color1);
}

.btn {
  text-wrap: nowrap;
}

.btn-lg {
  font-size: 1rem;
}

.Toastify {
  text-align: left;
}
.dropdown-menu {
  font-size: 14px;
}
.order_summery_holder input {
  border-width: 0px 0px 2px 0px;
  border-radius: 0;
  width: 160px;
  max-width: 100%;
}
.couponSection {
  position: relative;
  display: inline-flex;
  align-items: center;
}

/* .couponSection>button {
   position: absolute;
   top: 8px;
   right: 8px;
} */

.couponSection .btn:not(.btn-group > .btn) {
  top: 8px;
  right: 8px;
  border-width: 0;
  padding: 4px;
  height: 20px;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: absolute;
  color: var(--color3);
  font-weight: bold;
}

.couponSection input {
  padding-right: 30px;
}

.cartTable select.inline-select {
  border: 0px solid;
  background: var(--color16);
  border-radius: 5px;
  padding: 3px;
}
.cartTable select.inline-select:hover {
  background: var(--color18);
}

.cartTable p {
  line-height: 20px;
}
form.coupon.applied-coupon input {
  width: 0;
  border-width: 0px;
}

hr {
  border-color: #a5a5a5;
}

label.input-label {
  font-weight: bold;
  font-size: 1rem;
  color: #838383;
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

input.form-control[type="search"] {
  background: url("../public/images/search_icon.png");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 7px center;
  padding-left: 28px;
}

.newCardHeader {
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
  padding: 15px;
  margin: 0;
}
.priceInfoCard .priceInfo .row:last-child hr {
  display: none;
}
.productSection {
  max-height: calc(100vh - 218px);
  overflow: auto;
  /* min-height: 500px; */
  padding-top: 1rem;
}

.max-w-200 {
  width: 33% !important;
}

.mobileTd {
  display: none;
}

.input-sm {
  height: auto;
  padding: 5px 10px;
  font-size: 14px;
}


.qtySection > span {
  width: 45px;
  display: inline-block;
  padding: 5px;
  text-align: center;
  background-color: #fff;
}

td.desktopTd.qtySection {
  width: 140px;
}

.cartTable select {
  font-size: 14px;
  padding-right: 17px;
}

.cartTable td {
  padding: 10px !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background: var(--color3) !important;
}
.custom_check * {
  cursor: pointer;
}
.custom_check {
  position: relative;
  cursor: pointer;
}
.custom_check_wrapper {
  background: var(--color7);
  border-radius: 50px;
  padding: 0px 10px;
  padding-right: 25px;
  padding-left: 10px;
  transition: all 0.3s;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.4);
}
.custom_check_wrapper > span {
  background: var(--color5);
  display: block;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  position: absolute;
  right: 3px;
  top: 3px;
  transition: all 0.3s;
  border: 4px solid var(--color3);
}
.custom_check.active .custom_check_wrapper > span {
  background: var(--color3);
  border: 4px solid var(--color5);
  left: 3px;
}
.custom_check.active .custom_check_wrapper {
  padding-left: 25px;
  padding-right: 10px;
  background: var(--color3);
  color: var(--color5);
}
.custom_check > input.form-control {
  height: 40px !important;
}
.removebtn:active,
.removebtn:focus,
.removebtn:hover,
.copybtn:active,
.copybtn:focus,
.copybtn:hover,
.copybtn,
.removebtn {
  position: absolute;
  top: 5px;
  font-size: 1.2rem;
  background: #fff !important;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 10px 12px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  color: #000 !important;
}
.removebtn {
  right: 5px;
}
.copybtn {
  right: 45px;
}
.resultList {
  max-height: 250px;
  overflow: auto;
  border: 1px solid #ddd;
  position: absolute;
  top: 90%;
  width: 100%;
  background: #fff;
  z-index: 1;
  min-height: 120px !important;
}

.resultList ul {
  list-style: none;
  padding: 0;
}
.resultList ul li {
  display: block;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ddd;
  color: #000;
  text-decoration: none;
}
.resultList ul li.selected {
  pointer-events: none;
  opacity: 0.3;
}
.tagSelector {
  position: relative;
}
.tagSelector,
.tagSelector > div {
  position: relative;
  min-height: 43px;
}
.overflow-ellipsis-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.table-responsive {
  min-height: 440px;
}

@media only screen and (max-width: 1200px) {
  .order-summery h5 {
    font-size: 16px;
  }
  .max-w-200 {
    width: 33% !important;
  }

  .table_filter.equal_space > .table_filter_item {
    min-width: 33.3% !important;
  }
}

@media only screen and (max-width: 768px) {
  .table_filter.equal_space > .table_filter_item {
    min-width: 100% !important;
  }
  /* .cartTable tbody tr td:last-child {
      border-bottom: 1px solid #000;
      padding-bottom: 10px !important;
   }
   .cartTable tbody tr td h6 {margin: 0}
   .cartTable .table-hover>tbody>tr:hover>* {
      --bs-table-color-state: var(--bs-table-hover-color);
   }



   .cartTable tbody tr td:first-child {
      border-top: 1px solid #000;
      padding-top: 10px !important;
   }

   .cartTable tbody tr, .cartTable tbody tr td {
      display: block;
      width: 100% !important;
      padding: 0px !important;
      border-width: 0 !important;
   }

   .cartTable td, .cartTable th {
      padding: 10px 5px !important;
   } */
  br.bg-tag {
    display: none;
  }

  .cartTable p {
    font-size: 12px;
    margin-top: 5px;
  }
  .cartTable h6 {
    font-size: 14px;
    margin-bottom: 10px !important;
  }

  .mobileTd {
    display: block;
  }

  td.desktopTd {
    display: none;
  }

  .max-w-200 {
    width: 100% !important;
  }

  .container {
    padding: 0 10px !important;
  }

  .main {
    display: block;
  }

  .table-responsive {
    max-width: calc(100%);
  }

  .table_filter_item {
    display: block;
  }

  .table_filter_item > label {
    display: block;
  }

  .table_filter.equal_space > .table_filter_item {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .couponSection > button {
    top: 4px;
    right: 4px;
  }
}
@media only screen and (max-width: 640px) {
  .table_filter.equal_space > .table_filter_item {
    width: 100%;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-size-adjust: 100%;
}

body {
  font-size: 14px;
}

.main {
  display: flex;
  font-family: "Public Sans", sans-serif;
}

.disabledDiv {
  opacity: 0.5;
  pointer-events: none;
}

.tooltip {
  position: absolute;
  z-index: 100000;
}

.ck-content {
  min-height: 100px;
}
.ck-balloon-panel {
  display: none !important;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.apexcharts-tooltip.apexcharts-theme-light.apexcharts-active {
  border-radius: 10px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}
.apexcharts-tooltip .apexcharts-tooltip-title {
  display: none;
}
/* .customMultiUpload {
   border: 3px dashed #dedede;
   padding: 16px;
   border-radius: 8px;
   text-align: center;
   position: relative;
 }
  */
.customMultiUpload .btn.btn-primary {
  height: 90px;
  width: 90px;
  border: 2px dashed #b6b6b6;
  background: transparent;
  color: #797979;
  font-weight: bold;
  background: #ddd;
  white-space: break-spaces;
}
.customMultiUpload {
  border: 3px dashed #dedede;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  display: flex;
  /* flex-direction: row-reverse; */
  align-items: center;
  justify-content: center;
}

.customMultiUpload input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.customMultiUpload h5 {
  font-size: 20px;

  display: inline-block;
}
.theme-color {
  color: var(--color3);
}
.customMultiUpload small {
  color: rgba(199, 157, 52, 0.8);
}
.customMultiUpload .content {
  padding: 0;
}
.filePreview small {
  position: absolute;
  z-index: 0;
  bottom: 5px;
  background: #fff;
  border-radius: 10px;
  padding: 0px 10px;
  width: calc(100% - 8px);
  font-size: 11px;
  left: 4px;
  font-weight: 800;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.3);
}

.previewHolder {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1000;
  justify-content: center;
}

.filePreview {
  height: 90px;
  width: 80px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 10px 10px;
}

.filePreview img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.previewHolder .actionbtn {
  position: absolute;
  right: 5px;
  top: 5px;
}

.filePreview button {
  width: 25px;
  height: 25px;
  border-radius: 20px;
  border-width: 0px;
  background: #c79d34;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  flex-grow: 1;
  margin-top: 0px;
}

.container {
  max-width: 100%;
  padding: 0 24px;
}

.rangeSlider__ {
  background: var(--color2);
}

.rangeSlider__ > div {
  background: var(--color1);
}

.filter > div {
  display: flex;
}

.table_filter.equal_space {
  gap: 0px;
  margin: 0 -10px;
}

.table_filter.equal_space > .table_filter_item {
  width: 25%;
  padding: 0 10px;
  min-width: 360px !important;
}

.react_select,
.table_filter.equal_space .react_select div[class*="-control"] {
  width: 100%;
}

.filter > div {
  display: flex;
}

.table_filter.equal_space {
  gap: 0px;
  margin: 0 -10px;
}

.table_filter {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.table_filter.equal_space > .table_filter_item {
  width: 25%;
  padding: 0 10px;
  min-width: 360px !important;
}

.react_select,
.table_filter.equal_space .react_select div[class*="-control"] {
  width: 100%;
}

.table_filter .btn {
  min-height: 53px;
}

.table_filter_item {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.table_filter .btn {
  min-height: 53px;
}

.table_filter_item {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.btn {
  text-wrap: nowrap;
}

.btn-lg {
  font-size: 1rem;
}

.Toastify {
  text-align: left;
}
.dropdown-menu {
  font-size: 14px;
}
.order_summery_holder input {
  border-width: 0px 0px 2px 0px;
  border-radius: 0;
  width: 160px;
  max-width: 100%;
}
.couponSection {
  position: relative;
  display: inline-flex;
  align-items: center;
}

/* .couponSection>button {
   position: absolute;
   top: 8px;
   right: 8px;
} */

.couponSection .btn:not(.btn-group > .btn) {
  top: 8px;
  right: 8px;
  border-width: 0;
  padding: 4px;
  height: 20px;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: absolute;
  color: var(--color3);
  font-weight: bold;
}

.couponSection input {
  padding-right: 30px;
}

.cartTable p {
  line-height: 20px;
}
form.coupon.applied-coupon input {
  width: 0;
  border-width: 0px;
}

hr {
  border-color: #a5a5a5;
}

label.input-label {
  font-weight: bold;
  font-size: 1rem;
  color: #838383;
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

label.input-label span svg {
  color: var(--color1);
}

input.form-control[type="search"] {
  background: url("../public/images/search_icon.png");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 7px center;
  padding-left: 28px;
}

.newCardHeader {
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
  padding: 15px;
  margin: 0;
}
.enlarge_img {
  transition: all 0.3s;
}
.enlarge_img:hover {
  transform: scale(3);
  transform-origin: 0;
}
.productSection {
  max-height: calc(100vh - 218px);
  overflow: auto;
  /* min-height: 500px; */
  padding-top: 1rem;
}

.max-w-200 {
  width: 33% !important;
}

.mobileTd {
  display: none;
}



.qtySection > span {
  width: 45px;
  display: inline-block;
  padding: 5px;
  text-align: center;
  background-color: #fff;
}

td.desktopTd.qtySection {
  width: 140px;
}

.cartTable select {
  font-size: 14px;
  padding-right: 17px;
}

.cartTable td {
  padding: 10px !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background: var(--color3) !important;
}
.custom_check * {
  cursor: pointer;
}
.custom_check {
  position: relative;
  cursor: pointer;
}
.custom_check_wrapper {
  background: var(--color7);
  border-radius: 50px;
  padding: 0px 10px;
  padding-right: 25px;
  padding-left: 10px;
  transition: all 0.3s;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.4);
}
.custom_check_wrapper > span {
  background: var(--color5);
  display: block;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  position: absolute;
  right: 3px;
  top: 3px;
  transition: all 0.3s;
  border: 4px solid var(--color3);
}
.custom_check.active .custom_check_wrapper > span {
  background: var(--color3);
  border: 4px solid var(--color5);
  left: 3px;
}
.custom_check.active .custom_check_wrapper {
  padding-left: 25px;
  padding-right: 10px;
  background: var(--color3);
  color: var(--color5);
}
.custom_check > input.form-control {
  height: 40px !important;
}
.circleImg {
  height: 100px;
  width: 100px;
  border: 1px solid #ddd;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.circleImg > svg {
  font-size: 60px;
  color: var(--color1);
}
.profileBg {
  margin: -16px -16px -16px -16px;
  padding: 24px 15px 15px 15px;
  background: url('../public/images/profile_Bg.jpg');
}
.customLoadingScreen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #fff; */
  font-size: 20px;
  font-weight: bold;
  backdrop-filter: blur(3px);
}

ul.fancyList {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
ul.fancyList >li {
  display: flex;
  align-items: center;
  text-align: center;
}

ul.fancyList .priceVal:last-child {
  border-right: 0px solid;
}
ul.fancyList .priceVal {
  border-right: 1px solid #ddd;
  padding: 3px 10px;
  display: block;
  width: 100%;
  margin: 0px 0;
}
@media only screen and (max-width: 1200px) {
  .order-summery h5 {
    font-size: 16px;
  }
  .max-w-200 {
    width: 33% !important;
  }

  .table_filter.equal_space > .table_filter_item {
    min-width: 33.3% !important;
  }
}

@media only screen and (max-width: 768px) {
  .table_filter.equal_space > .table_filter_item {
    min-width: 100% !important;
  }
  /* .cartTable tbody tr td:last-child {
      border-bottom: 1px solid #000;
      padding-bottom: 10px !important;
   }
   .cartTable tbody tr td h6 {margin: 0}
   .cartTable .table-hover>tbody>tr:hover>* {
      --bs-table-color-state: var(--bs-table-hover-color);
   }



   .cartTable tbody tr td:first-child {
      border-top: 1px solid #000;
      padding-top: 10px !important;
   }

   .cartTable tbody tr, .cartTable tbody tr td {
      display: block;
      width: 100% !important;
      padding: 0px !important;
      border-width: 0 !important;
   }

   .cartTable td, .cartTable th {
      padding: 10px 5px !important;
   } */
  br.bg-tag {
    display: none;
  }

  .cartTable p {
    font-size: 12px;
    margin-top: 5px;
  }
  .cartTable h6 {
    font-size: 14px;
    margin-bottom: 10px !important;
  }

  .mobileTd {
    display: block;
  }

  td.desktopTd {
    display: none;
  }

  .max-w-200 {
    width: 100% !important;
  }

  .container {
    padding: 0 10px !important;
  }

  .main {
    display: block;
  }

  .table-responsive {
    max-width: calc(100%);
  }

  .table_filter_item {
    display: block;
  }

  .table_filter_item > label {
    display: block;
  }

  .table_filter.equal_space > .table_filter_item {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .couponSection > button {
    top: 4px;
    right: 4px;
  }
}
@media only screen and (max-width: 640px) {
  .table_filter.equal_space > .table_filter_item {
    width: 100%;
  }
}
