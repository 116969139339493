/* Scrollbar Track */
*:hover ::-webkit-scrollbar {
   opacity: 1;
}
*::-webkit-scrollbar {
   width: 6px; /* Set the width of the scrollbar track */
   height: 6px;
   opacity: 0;
 }
 
 /* Scrollbar Thumb (the draggable part of the scrollbar) */
 *::-webkit-scrollbar-thumb {
   background-color: #AAB8BC; /* Set the color of the scrollbar thumb */
   border-radius: 6px; /* Round the corners of the thumb */
 }
 
 /* Scrollbar Track when hovering over it */
 *::-webkit-scrollbar-track:hover {
   background-color: #ddd; /* Set the color when hovering over the track */
 }


 /* Scrollbar Width (thin or auto) */
*{scrollbar-width: thin}

/* Scrollbar Color */
*{scrollbar-color: #888 #ddd;} /* thumb color, track color */