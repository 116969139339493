.no-arrow.nav-item.dropdown .dropdown-toggle::after {
  display: none;
}
header > nav {
  position: fixed !important;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 1000 !important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
}
header {
  margin-bottom: 75px;
}
.navbar {
  background: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: saturate(180%) blur(10px);
  position: sticky;
  width: 100%;
  z-index: 0;
  top: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.navbar .navbar-nav {
  display: flex;
  align-items: center;
}

.navbar .iconlink {
  font-size: 1.3rem;
}
.profile_img > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  header {
    display: none;
  }
}
