ul.statusTracking {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.statusTracking > li {
  padding: 0px 20px;
  position: relative;
  font-weight: bold;
}

li.current-status ~ li {
  color: var(--color15);
}

li.current-status ~ li {
  color: var(--color2) !important;
}

li.current-status ~ li,
li.current-status ~ li svg {
  color: var(--color5);
}

ul li.current-status ~ li span.icon {
  /* background: var(--color2); */
  background: var(--color2) !important;
}

ul.statusTracking > li {
  padding: 0px 20px;
  position: relative;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

ul.statusTracking li span.icon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  background: var(--color1) !important;
}

ul.statusTracking > li svg {
  font-size: 23px;
  color: var(--color5);
}

ul.statusTracking > li:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: var(--color1);
  position: absolute;
  right: -60%;
  bottom: 39px;
}

ul.statusTracking > li:last-child:after {
  display: none;
}
