.react-date-picker,
input.form-control {
  height: 43px;
  transition: all 0.3s;
  font-size: 14px;
}

.form-control[type=file] {
  padding: 10px 16px;
}

textarea.form-control {
  min-height: 43px;
  font-size: 14px;
}

.form-control.input-sm {
  height: 30px !important;
  padding:5px
}

input.form-control:hover,
input.form-control:focus {
  box-shadow: 0px 0px 0px 1px var(--color3);
  border-color: var(--color3);
}

input:invalid {
  box-shadow: 0px 0px 0px 1px var(--color9);
  border-color: var(--color9);
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari/Edge */
  color: var(--color14);
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color14);
}

input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color14);
}

input::placeholder {
  color: var(--color14);
}

.react-date-picker {
  padding: 3.6px 6px !important;
}

.react-date-picker__clear-button {
  display: none;
}

.react-date-picker__wrapper {
  border-width: 0px !important;
}

.passwordWrapper {
  position: relative;
}

.passwordWrapper span.eyeicon {
  position: absolute;
  top: 2px;
  right: 12px;
  font-size: 22px;
}

.error {
  color: red;
}
.input-group > *:not(input) {
  background: var(--color1);
  height: 43px;
  display: flex;
  align-items: center;
  border-radius: 0px 10px 10px 0;
  color: var(--color5);
}
select.form-control {
  height: 43px;

  --bs-form-select-bg-img: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  color: var(--bs-body-color);
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .input-group > *:not(input),
  .form-control.input-sm {
    height: 30px !important;
  }
  select.form-control,
  .react-date-picker,
  input.form-control {
    font-size: 0.8rem;
    height: 40px;
    transition: all 0.3s;
    padding: 0.2rem 0.5rem;
  }

  .btn:not(.btn-group > .btn) {
    padding: 0.2rem 0.5rem;
    font-size: 13px;
    height: 30px;
  }
}
