.modal {
   z-index: 100000;
}
.modal-header {
   border-bottom: 0px solid;
   padding: 20px 30px;
}

.modal-title {
   font-size: 20px;
}

.modal-body {
   padding: 0px 30px 20px;
}