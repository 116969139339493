.card {
  background-color: var(--color5);
  color: var(--color13);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  /* overflow: hidden; */
  position: relative;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 16px;
  z-index: 0;
  border: 0px solid;
}
.listView.card_product {
  display: flex;
  flex-direction: row;
}
.listView.card_product.card select {
  width: 90px !important;
}
.listView.card_product.card .col {
  max-width: max-content;
}
.listView.card_product.card > .img {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  object-fit: contain;
  aspect-ratio: 1/1;
  margin: 10px;
}
.listView.card_product.card > .img > img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.card_product h6 {
  margin: 0;
  font-weight: 600;
  line-height: 1.5;
  font-size: 0.875rem;
  font-family: Public Sans, sans-serif;
}

.card_product > .img > img {
  height: 180px;
  object-fit: cover;
}

.card_product .qtyinput {
  width: 80px;
}

.card_product .qtyinput + div {
  width: calc(100% - 80px);
}

.cartBtn {
  position: absolute;
  top: 120px;
  left: calc(50% - 56px);
  opacity: 0;
  transition: all 0.3s;
}

.card_product:hover .cartBtn {
  opacity: 1;
}

.card_product select {
  font-size: 14px;
  height: 30px !important;
  /* width: 65px!important; */
  padding: 5px 20px 5px 5px !important;
  background-position: calc(100% - 5px) 10px !important;
}

.card_product input {
  height: 40px !important;
  background: #ddd;
}

.filterSection {
  position: relative;
  z-index: 1;
}

.colorIndicator > .color {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50px;
  border: 1px solid #ddd;
}

@media screen and (max-width: 640px) {
  .card {
    border-radius: 7px !important;
  }
  .productCardPrice {
    font-size: 12px;
  }
  .card_product h6 {
    font-size: 0.75rem !important;
  }
  .card_product > img {
    height: 130px;
  }
  .cartBtn {
    top: 70px;
    left: calc(50% - 41px);
  }
  .cartBtn button {
    padding: 5px !important;
    font-size: 13px !important;
  }
  .newCardHeader {
    padding: 8px !important;
  }

  .card_product select {
    font-size: 11px;
    height: 25px !important;
    padding: 1px 5px;
  }
}
