.right-sidebar {
   position: fixed;
   right: 0;
   top: 0;
   background: var(--color5);
   padding: 1.3rem 0;
   z-index: 1000;
   width: 260px;
   height: 100vh;
   box-shadow: -8px 24px 24px 12px rgba(22, 28, 36, 0.16);
   transition: all .3s;
}

.right-sidebar-backdrop {
   position: fixed;
   display: flex;
   align-items: center;
   justify-content: center;
   right: 0;
   bottom: 0;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.5);
   -webkit-tap-highlight-color: transparent;
   background: rgb(22,28,36);
   background: -moz-linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
   background: -webkit-linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
   background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
   z-index: -1;
}

.right-sidebar-wrapper {
   position: fixed;
   z-index: 10000;
   left: 0;
   top: 0;
   height: 100%;
   width: 100%;
   transition: all .3s .3s;
}
.right-sidebar-wrapper.close {
   pointer-events: none;
   visibility: hidden;
}
.close .right-sidebar {
   width: 0;
   padding-left: 0;
   padding-right: 0;
}

.right-sidebar > .header {
   display: flex;
   align-items: center;
   justify-content: center;
   border-bottom: 1px solid var(--color6);
   margin-bottom: 10px;
   padding: 0 1.3em 1.3em;
}

.right-sidebar ul {
   list-style: none;
   padding: 0;
}

.right-sidebar ul li>a {
   color: var(--color13);
   display: block;
   padding: .6em 1.3em;
   text-decoration: none;
}

.right-sidebar ul li>a:hover {
   background: var(--color6);
}