table {
  line-height: 1.7rem;
}

.ltTable tr > *:first-child {
  border-radius: 10px 0px 0px 10px;
}

.ltTable tr > *:last-child {
  border-radius: 0px 10px 10px 0px;
}

.ltTable th {
  line-height: 1.5rem;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px dashed var(--color7);
  text-align: left;
  padding: 16px;
  font-size: 14px;
  color: var(--color14);
  font-weight: 600;
  background-color: var(--color9);
}

.ltTable td {
  line-height: 1.57143;
  font-size: 0.875rem;
  font-weight: 400;
  vertical-align: center;
  border-bottom: 1px dashed var(--color7);
  text-align: left;
  padding: 16px;
  color: var(--color13);
  vertical-align: middle;
}

tr.secondTHead {
  border-top: 1px solid #ddd;
}

tr.secondTHead th {
  font-weight: bold;
  color: #000 !important;
}

.ltTable .dropdown-menu {
  max-width: initial;
  width: auto !important;
}

.ltTable .dropdown-menu {
  color: var(--color13);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: absolute;
  min-width: 16px;
  min-height: 16px;
  outline: 0px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(var(--color5), var(--alpha9));
  background-repeat: no-repeat, no-repeat;
  background-position: right top, left bottom;
  background-size: 50%, 50%;
  padding: 4px;
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) -20px 20px 40px -4px;
  border-radius: 10px;
  width: 140px;
  overflow: inherit;
  margin-top: -6px;
  right: 0%;
  border-width: 0px;
}

.ltTable .dropdown-item {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  white-space: nowrap;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 6px 8px;
  border-radius: 6px;
}

.ltTable .dropdown-item svg {
  font-size: 15px;
  margin-right: 10px;
}

.ltTable .dropdown-item:hover {
  text-decoration: none;
  background-color: #f4f6f8;
}

.ltTable .dropdown-toggle {
  padding: 5px;
  background: transparent;
  border: 0px;
  color: var(--color13);
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ltTable .dropdown-toggle:focus,
.ltTable .dropdown-toggle:active {
  color: var(--color13);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: #f4f6f8;
}

.ltTable .dropdown-toggle:hover {
  background-color: rgba(99, 115, 129, 0.08);
}

.ltTable .dropdown-toggle:after {
  display: none;
}
.ltTable .th_searchable {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 120px;
  width: 100%;
  justify-content: space-between;
  /* flex-direction: row-reverse; */
}
.ltTable .th_searchable select,
.ltTable .th_searchable input {
  border: 0px solid;
  transition: all 0.3s;
  position: absolute;
  width: 0;
  top: -5px;
  height: 30px;
  display: none;
  right: 20px;
}
.ltTable .th_searchable select.show-input,
.ltTable .th_searchable input.show-input {
  width: calc(100% - 20px);
  display: block;
}
.ltTable .th_searchable > .thTitle {
  display: none;
}
.ltTable .th_searchable > .thTitle.show-thTitle {
  display: inline-block;
}
.ltTable .th_searchable .icon {
  font-size: 18px;
  margin-left: auto;
  display: inline-block;
}
.ltTable .th_searchable .icon.toggleIcon .searchIcon,
.ltTable .th_searchable .icon .closeIcon {
  display: none;
}
.ltTable .th_searchable .icon .searchIcon,
.ltTable .th_searchable .icon.toggleIcon .closeIcon {
  display: inline-block;
}
/* .ltTable .th_searchable:hover input {
  opacity: 1;
} */
.tableInnerCard.card {
  position: absolute;
  top: 10px;
  left: 0;
  width: 250px;
}