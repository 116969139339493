.dashboardCard {
   text-align: center;
}

.dashboardCard img {
   height: 60px;
   filter: drop-shadow(6px 3px 5px rgba(0, 0, 0, 0.5));
}

.dashboardCard.variant-success img {
   filter: drop-shadow(6px 3px 5px rgba(0, 194, 32, 0.5));
}

.dashboardCard.variant-danger img {
   filter: drop-shadow(6px 3px 5px rgba(194, 0, 0, 0.5));
}

.dashboardCard.variant-warning img {
   filter: drop-shadow(6px 3px 5px rgba(194, 155, 0, 0.5));
}

.dashboardCard.variant-primary img {
   filter: drop-shadow(6px 3px 5px rgba(0, 194, 194, 0.5));
}

.dashboardCard h3 {
   font-weight: bold;
}

.dashboardCard.variant-primary h3 {
   color: rgb(0, 76, 76);
}

.dashboardCard.variant-success h3 {
   color: rgb(0, 118, 20)
}

.dashboardCard.variant-warning h3 {
   color: rgb(120, 82, 0)
}

.dashboardCard.variant-danger h3 {
   color: rgb(117, 0, 0)
}

.dashboardCard.variant-danger {
   background: linear-gradient(135deg, rgba(255, 172, 130, 0.2), rgba(255, 86, 48, 0.2)) rgb(255, 255, 255);
}

.dashboardCard.variant-success {
   background: linear-gradient(135deg, rgba(130, 255, 157, 0.2), rgba(48, 255, 93, 0.2)) rgb(255, 255, 255);
}

.dashboardCard.variant-warning {
   background: linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255);
}

.dashboardCard.variant-primary {
   background: linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255);
}