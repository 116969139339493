.fileImporter {
   padding: 13px;
   position: relative;
   border-radius: 10px;
   border: 1px solid #ddd;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;

}
.fileImporter > div {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 100%; 
}
.fileImporter>input {
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   opacity: 0;
   z-index: 1;
   cursor: pointer;
}